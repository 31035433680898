
$(document).on('turbolinks:load', function() {

  let form = document.querySelector('#new_match');
  if (!form){
    form = document.querySelector('[id^="edit_match_"]');
  }
  if (form) {// Custom validation on form submit
    const lessonRequestSelect = document.querySelector('#match_lesson_request_id');
    const teacherSelect = document.querySelector('#match_teacher_id');
    const studentSelect = document.querySelector('#match_student_id');

    form.addEventListener('submit', function (e) {
      let valid = true;

      if (!lessonRequestSelect.value) {
        valid = false;
        $('#lesson-request-group').addClass('border border-danger');
      } else {
        $('#lesson-request-group').removeClass('border border-danger');
      }

      if (!studentSelect.value) {
        valid = false;
        studentSelect.classList.add('is-invalid');
        $('#student-group').addClass('border border-danger');
      } else {
        $('#student-group').removeClass('border border-danger');
      }

      if (!teacherSelect.value) {
        valid = false;
        $('#teacher-group').addClass('border border-danger');

      } else {
        $('#teacher-group').removeClass('border border-danger');
      }

      if (!valid) {
        e.preventDefault();
        e.stopPropagation()
      }
    });
  }
});
