$(document).on('turbolinks:load', function() {
  const lessonRequestSelect = document.querySelector('#match_lesson_request_id');
  if (lessonRequestSelect) {
    const choicesLessonRequest = new Choices(lessonRequestSelect, {
      searchEnabled: true,   // Enable search functionality
      itemSelectText: 'Select',    // Customize the text shown for selecting an item
      shouldSort: false,     // Keep the order of the original collection
      renderChoiceLimit: 10,
      classNames:{
        input:['form-control']
      },
    });

    async function fetchLessonRequests(query) {
      if (query.length >= 1) {
        try {
          const response = await fetch(`/super_admin/lesson_requests/search?query=${encodeURIComponent(query)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error('Failed to fetch');
          }
          const data = await response.json();
          let options = [];

          data.forEach(function(user) {
            options.push({value:user.id,label:user.name})
          });
          return options;

        } catch (error) {
          console.log("error fetching lesson requests")
          return []
        }
      } else {
        return []
      }
    }
    // listener for search typing to fire API call and populate choices
    lessonRequestSelect.addEventListener("search", async function (event) {
      choicesLessonRequest.clearChoices();
      choicesLessonRequest.setChoices(await fetchLessonRequests(event.detail.value));
      // TODO:
      // The right way is to send setChoices() a promise so that it renders "Loading..."
      // But trying this line:
      // choicesObject.setChoices(async () => await fn(event.detail.value));
      // Causes the text box to unfocus and prevents additional typing after search
    });
  }

  const teacherSelect = document.querySelector('#match_teacher_id');
  if (teacherSelect) {
    const choicesTeacher = new Choices(teacherSelect, {
      searchEnabled: true,   // Enable search functionality
      itemSelectText: 'Select',    // Customize the text shown for selecting an item
      shouldSort: false,     // Keep the order of the original collection
      renderChoiceLimit: 10,
      classNames:{
        input:['form-control']
      },
    });

    async function fetchTeachers(query) {
      if (query.length >= 1) {
        try {
          const response = await fetch(`/super_admin/users/search_teacher?query=${encodeURIComponent(query)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });
          if (!response.ok) {
            throw new Error('Failed to fetch');
          }
          const data = await response.json();
          let options = [];

          data.forEach(function(user) {
            options.push({value:user.id,label:user.name})
          });
          return options;

        } catch (error) {
          console.log("error fetching teachers")
          return []
        }
      } else {
        return []
      }
    }
    // listener for search typing to fire API call and populate choices
    teacherSelect.addEventListener("search", async function (event) {
      choicesTeacher.clearChoices();
      choicesTeacher.setChoices(await fetchTeachers(event.detail.value));
      // TODO:
      // The right way is to send setChoices() a promise so that it renders "Loading..."
      // But trying this line:
      // choicesObject.setChoices(async () => await fn(event.detail.value));
      // Causes the text box to unfocus and prevents additional typing after search
    });
  }

  const studentSelect = document.querySelector('#match_student_id');
  if (studentSelect) {
    const choicesStudent = new Choices(studentSelect, {
      searchEnabled: true,   // Enable search functionality
      itemSelectText: 'Select',    // Customize the text shown for selecting an item
      shouldSort: false,     // Keep the order of the original collection
      renderChoiceLimit: 10,
      classNames:{
        input:['form-control']
      },
    });

    async function fetchStudents(query) {
      if (query.length >= 1) {
        try {
          const response = await fetch(`/super_admin/users/search_student?query=${encodeURIComponent(query)}`, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json'
            }
          });

          if (!response.ok) {
            throw new Error('Failed to fetch');
          }

          const data = await response.json();
          let options = [];

          data.forEach(function(user) {
            options.push({value:user.id,label:user.name})
          });

          return options;

        } catch (error) {
          console.log("error fetching students")
        }
      } else {
        return []
      }
    }
    // listener for search typing to fire API call and populate choices
    studentSelect.addEventListener("search", async function (event) {
      choicesStudent.clearChoices();
      choicesStudent.setChoices(await fetchStudents(event.detail.value));
      // TODO:
      // The right way is to send setChoices() a promise so that it renders "Loading..."
      // But trying this line:
      // choicesObject.setChoices(async () => await fn(event.detail.value));
      // Causes the text box to unfocus and prevents additional typing after search
    });
  }
});
